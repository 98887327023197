import { lazy } from 'react';

//------------------------------------------//
// Static reusable route paths
// First level Parents
export const ORG_PATH = '/o';
export const USER_PATH = '/u';
export const GUEST_PATH = '/g';
export const TEAM_PATH = '/t';
export const SHARED_PATH = '/shared';
export const Leaderboard_Path = '/leaderboard';
export const NOTIFICATIONS_PATH = '/notifications';

// Second level Parents
export const CHALLENGE_PATH = '/challenge';
export const SUBMISSIONS_PATH = '/submission';
export const ASSETS_PATH = '/assets';
export const PREFERENCES_PATH = '/preferences';
export const POINTS_PATH = '/points';
export const CREDITS_PATH = '/credits';

// Actions
export const CREATE_PATH = '/create';
export const EDIT_PATH = '/edit';
export const DRAFT_EDIT_PATH = '/draft';

// Display
export const LIST_PATH = '/list';

/** Data
 * @editparamKey will act as - @UserProfileId @OrgProfileId @ChallengeId
 * @proposalKey will act as  - @SubimissionId and selected tab on the challenge details page.
 */
const editParamKey = '/:editKey';
const proposalKey = '/:proposalKey';
const reviewKey = '/:reviewKey';
const draftParamKey = '/:draftKey';
const pageNo = '/:pageNo';

//------------------------------------------//

//------------------------------------------//
// Public routes
export const HERO_PATH = '/';
export const DOCS_PATH = '/docs';
export const HELP_PATH = '/help';
export const ALL_ORG_LIST = ORG_PATH + LIST_PATH;
export const USER_PROFILE_DETAILS_PATH = USER_PATH;
export const ORG_DETAILS_PATH = ORG_PATH;
export const TEAM_DETAILS_PATH = TEAM_PATH;
export const USER_ORG_LIST_PATH = ORG_PATH + LIST_PATH;
export const USER_CHALLENGE_LIST_PATH = USER_PATH + CHALLENGE_PATH + LIST_PATH;

export const SHARED_CHALLENGE_DETAILS_PATH = SHARED_PATH + CHALLENGE_PATH;

// To access this route wallet need to be connected
export const SIGNUP_PATH = '/signup';

// Some paths could be a same string with different variable names for better understanding.
export const GUEST_PROFILE_CREATE_PATH = GUEST_PATH + USER_PATH + CREATE_PATH;
export const GUEST_CHALLENGE_LIST_PATH = CHALLENGE_PATH + LIST_PATH;
export const GUEST_CHALLENGE_DETAILS_PATH = GUEST_PATH + CHALLENGE_PATH;
export const GUEST_ORG_LIST_PATH = GUEST_PATH + ORG_PATH + LIST_PATH;
export const GUEST_ORG_DETAILS_PATH = ORG_PATH;

// Login protected routes
export const ORG_PROFILE_CREATE_PATH = ORG_PATH + CREATE_PATH;
export const ORG_PROFILE_EDIT_PATH = ORG_PATH + EDIT_PATH;
export const ORG_PROFILE_DETAILS_PATH = ORG_PATH;
export const ORG_CHALLENGE_CREATE_PATH = CHALLENGE_PATH + CREATE_PATH;
export const ORG_CHALLENGE_EDIT_PATH = CHALLENGE_PATH + EDIT_PATH;
export const ORG_DRAFT_EDIT_PATH = CHALLENGE_PATH + DRAFT_EDIT_PATH;
export const ORG_CHALLENGE_LIST_PATH = ORG_PATH + CHALLENGE_PATH + LIST_PATH;

export const USER_PROFILE_EDIT_PATH = USER_PATH + EDIT_PATH;
export const USER_ORG_DETAILS_PATH = ORG_PATH;
export const USER_ORG_PROFILE_LIST_PATH = USER_PATH + ORG_PATH + LIST_PATH;
export const USER_TEAMS_LIST_PATH = USER_PATH + TEAM_PATH + LIST_PATH;
export const USER_SUBMISSIONS_LIST_PATH = USER_PATH + SUBMISSIONS_PATH + LIST_PATH;
export const USER_ASSETS_PATH = USER_PATH + ASSETS_PATH;
export const USER_PREFERENCES_PATH = USER_PATH + PREFERENCES_PATH;

export const CREATE_TEAM_PATH = TEAM_PATH + CREATE_PATH;
export const UPDATE_TEAM_PATH = TEAM_PATH + EDIT_PATH;
export const TEAM_SUBMISSIONS_LIST_PATH = TEAM_PATH + SUBMISSIONS_PATH + LIST_PATH;

export const USER_POINTS_PATH = USER_PATH + POINTS_PATH;
export const USER_CREDITS_PATH = USER_PATH + CREDITS_PATH;
//------------------------------------------//

//-----Temporary Path-----//
export const ADDRESS_NOT_VALID = '/addressnotvalid';
//-----------------------//

export const pulicRoutePaths = [HERO_PATH, DOCS_PATH, HELP_PATH, SIGNUP_PATH];

export const sidebarConfigArr = [HERO_PATH, SIGNUP_PATH, ADDRESS_NOT_VALID];

export const headerNavItems = [
  { name: 'Features', href: '#features' },
  { name: 'Docs', href: 'https://docs.desights.ai/' },
];

export const commonRoutes = [
  {
    path: DOCS_PATH.slice(1),
    component: lazy(() => import('../../Screens/EmptyFallbacks/UnderConstruction')),
  },
  {
    path: HELP_PATH.slice(1),
    component: lazy(() => import('../../Screens/EmptyFallbacks/UnderConstruction')),
  },
];

export const privateRouteSources = [
  {
    // user - My profile
    path: USER_PROFILE_EDIT_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Profile/ProfileComponent')),
  },
  {
    path: USER_PROFILE_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Profile/UserProfile')),
  },
  {
    // user - My organization
    path: USER_ORG_PROFILE_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Organisations/OrganizationList')),
  },
  {
    // user - organisations
    path: USER_ORG_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Organisations/OrganizationList')),
  },
  {
    path: USER_ASSETS_PATH.slice(1),
    component: lazy(() => import('../../Screens/Profile/Assets')),
  },
  {
    path: USER_PREFERENCES_PATH.slice(1),
    component: lazy(() => import('../../Screens/Profile/Preferences')),
  },
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey + proposalKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey + proposalKey + reviewKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: USER_CHALLENGE_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Challenges')),
  },
  {
    path: USER_SUBMISSIONS_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Submissions/SubmissionList')),
  },
  {
    path: ORG_PROFILE_EDIT_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Profile/ProfileComponent')),
  },
  {
    path: ORG_PROFILE_CREATE_PATH.slice(1),
    component: lazy(() => import('../../Screens/Profile/ProfileComponent')),
  },
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey + proposalKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    // org - Challenges and initial page
    path: ORG_CHALLENGE_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Challenges')),
  },
  {
    path: ORG_CHALLENGE_CREATE_PATH.slice(1),
    component: lazy(() => import('../../Screens/Challenges/ChallengeForm')),
  },
  {
    path: ORG_CHALLENGE_EDIT_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeForm')),
  },
  {
    path: ORG_DRAFT_EDIT_PATH.slice(1) + draftParamKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeForm')),
  },
  {
    path: ORG_PROFILE_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Organisations/OrganizationProfile')),
  },
  {
    path: ORG_PROFILE_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Organisations/OrganizationProfile')),
  },
  //CREATE TEAM
  {
    path: CREATE_TEAM_PATH.slice(1),
    component: lazy(() => import('../../Screens/Teams/UserTeamForm')),
  },
  {
    // update team
    path: UPDATE_TEAM_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Teams/UserTeamForm')),
  },
  {
    path: TEAM_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Teams/TeamProfile')),
  },
  //user's teams path
  {
    path: USER_TEAMS_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Teams/TeamsList')),
  },
  //team's submissions path
  {
    path: TEAM_SUBMISSIONS_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Submissions/SubmissionList')),
  },
  {
    path: USER_POINTS_PATH.slice(1),
    component: lazy(() => import('../../Screens/Points/PointsPage')),
  },
  {
    path: USER_CREDITS_PATH.slice(1),
    component: lazy(() => import('../../Screens/Credits')),
  },
];

export const publicRouteSources = [
  {
    path: Leaderboard_Path.slice(1),
    component: lazy(() => import('../../Screens/Leaderboard')),
  },
  {
    path: GUEST_PROFILE_CREATE_PATH.slice(1),
    component: lazy(() => import('../../Screens/Profile/ProfileComponent')),
  },
  {
    path: GUEST_CHALLENGE_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Challenges')),
  },
  {
    path: GUEST_CHALLENGE_DETAILS_PATH.slice(1) + editParamKey + proposalKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: GUEST_CHALLENGE_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: GUEST_ORG_LIST_PATH.slice(1),
    component: lazy(() => import('../../Screens/Organisations/OrganizationList')),
  },
  {
    path: GUEST_ORG_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Organisations/OrganizationProfile')),
  },
  {
    path: ADDRESS_NOT_VALID.slice(1),
    component: lazy(() => import('../../Screens/Welcome/AddressNotValid')),
  },
  /**Adding challenge details without /g in public routes,might have to remove later*/
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    path: CHALLENGE_PATH.slice(1) + editParamKey + proposalKey,
    component: lazy(() => import('../../Screens/Challenges/ChallengeDetails')),
  },
  {
    // all - organisations
    path: ALL_ORG_LIST.slice(1),
    component: lazy(() => import('../../Screens/Organisations/OrganizationList')),
  },
  {
    path: USER_PROFILE_DETAILS_PATH.slice(1) + editParamKey,
    component: lazy(() => import('../../Screens/Profile/UserProfile')),
  },
  //notifications
  {
    path: NOTIFICATIONS_PATH.slice(1) + pageNo,
    component: lazy(() => import('../../Screens/Notifications')),
  },
];

// export const sharedRoutes = [
// 	{
// 		path: SHARED_CHALLENGE_DETAILS_PATH.slice(1) + editParamKey,
// 		component: lazy(() => import('../../Screens/Challenges/ChallengeDetails'))
// 	},
// ]
