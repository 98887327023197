import { Sidebar } from 'flowbite-react';
import { NavLink } from 'react-router-dom';

import { RootState, SideBarItem, SubMenu, renderSidebarListInterface } from '../../@types';
import { useSelector } from 'react-redux';

export const RenderSidebarList = ({
  renderSidebar,
  setSidebarOpen,
}: renderSidebarListInterface) => {
  const defaultProfile = useSelector((state: RootState) => state?.profile?.defaultProfile);

  return (
    <div>
      {renderSidebar.map((item: SideBarItem, index) => {
        return !item?.subPages?.length ? (
          <span
            key={index}
            onClick={() => {
              setSidebarOpen(false);
            }}
          >
            <NavLink
              caseSensitive={false}
              to={item.path}
              key={item.name}
              className={({ isActive }) => {
                return isActive
                  ? 'flex bg-primary-50 dark:bg-gray-700 mt-1 rounded-lg'
                  : 'mt-1 flex activehover';
              }}
            >
              <Sidebar.Item as='div' icon={item.icon} className='py-3 hover:bg-primary-50 relative'>
                {/* Container for the bell icon and red dot */}
                {/* Bell icon */}
                {/* <span className='mr-2'>{item.icon}</span> */}
                {/* Red dot */}
                {item.name === 'My Notifications' &&
                  defaultProfile?.unreadNotificationsCount > 0 && (
                    <div className='absolute w-2 h-2 rounded-full bg-red-500 top-[12px] left-[22px] '></div>
                  )}
                <p className='text-sm'>{item.name}</p>
              </Sidebar.Item>
            </NavLink>
          </span>
        ) : (
          <Sidebar.Collapse className=' !text-sm ' label={item.name} icon={item.icon}>
            {item?.subPages?.map((val: SubMenu, index) => {
              return (
                <span
                  key={index}
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                >
                  <NavLink
                    to={val.subPath}
                    key={val.subName}
                    className={({ isActive }) =>
                      isActive
                        ? 'bg-primary-50 flex mt-1 rounded-lg hover:bg-primary-50'
                        : 'mt-1 flex activehover'
                    }
                  >
                    <Sidebar.Item
                      as='div'
                      icon={val.subIcon}
                      href='#'
                      className='hover:bg-primary-50'
                    >
                      <div className='flex items-center text-sm light:hover:bg-primary-50'>
                        {val.subName}
                      </div>
                    </Sidebar.Item>
                  </NavLink>
                </span>
              );
            })}
          </Sidebar.Collapse>
        );
      })}
    </div>
  );
};
