import { HiCodeBracket, HiOutlineBanknotes, HiTableCells } from 'react-icons/hi2';
import { HiUserGroup } from 'react-icons/hi';

export * from './TabsConstants';
export * from './RouteConstants';
export * from './IconConstants';
export * from './FormObjectConstant';
export * from './FormConstants';
export * from './SidebarConstants';
export * from './ChallengeConstants';
export * from './APIConstants';
export * from './TxnConstants';
export * from './MetaConstants';

export const walletConnectConfig = {
  projectId: process.env.REACT_APP_WALLET_CONNECT_ID || '',
  ethereum: {
    appName: 'algomaxai',
  },
};

export const heroFeaturesInfo = [
  {
    name: 'Pools',
    description: 'Allocate funds to decentralised pools dedicated to a particular Challenge.',
    icon: HiOutlineBanknotes,
  },
  {
    name: 'Challenges',
    description: 'Define AI problems you want solved as challenges with incentives as rewards.',
    icon: HiCodeBracket,
  },
  {
    name: 'Dashboard',
    description: 'Manage all your challenges and submissions under a unified Dashboard.',
    icon: HiUserGroup,
  },
  {
    name: 'Leaderboard',
    description: 'Perform better than your peers and climb to the top of leaderboard',
    icon: HiTableCells,
  },
];

export const assetTableHeaders = ['Asset', 'Balance', ''];

// Temp Data
export const testAssets = [
  {
    icon: 'https://polygonscan.com/token/images/wETH_32.png',
    symbol: 'WETH',
    name: 'Wrapped Ether',
    balance: 1422.579552,
    price: 1584.7536,
  },
  {
    icon: 'https://polygonscan.com/token/images/centre-usdc_32.png',
    symbol: 'USDC',
    name: 'USD Coin',
    balance: 0.896861,
    price: 1.0,
  },
  {
    icon: 'https://polygonscan.com/token/images/matic_32.png',
    symbol: 'MATIC',
    name: 'Matic Token',
    balance: 0.995516,
    price: 1.11,
  },
  {
    icon: 'https://polygonscan.com/token/images/bat.png',
    symbol: 'BAT',
    name: 'BAT',
    balance: 0.228722,
    price: 0.255,
  },
  {
    icon: 'https://polygonscan.com/token/images/oceantoken_28.png',
    symbol: 'OCEAN',
    name: 'Ocean Token',
    balance: 0.327082,
    price: 0.36,
  },
];

// TEMP EVALUATION CRITERIA DATA

export const testEvalData = [
  {
    title: 'First title',
    score: 50,
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ',
  },
  {
    title: 'Second title',
    score: 50,
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ',
  },
  {
    title: 'Third title',
    score: 50,
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ',
  },
];

export const TEAM_STATUS_CONSTANSTS = {
  pending: 0,
  declined: 1,
  approved: 2,
};

/**Notification types/ids */

export const NOTIFICATION_TYPES = {
  TEAM_INVITE: 1, // when user invites someone to join his team
};
