import { IoWarningOutline, IoCloseOutline } from 'react-icons/io5';
import CommonModal from '../CommonModal';
import CommonButton from '../CommonButton';
import LoadingDots from '../LoadingDots';

interface WarningModalProps {
  show: boolean;
  warningText: string | number;
  secondaryText?: string | null | number;
  onButtonClick: () => void;
  onClose?: () => void; // Add a new prop for the close function
  showCloseIcon?: boolean;
  buttonText: string;
  extraText?: string;
  secondExtraText?: string;
  disableButton?: boolean;
  extraTextLoading?: boolean;
}

const CommonWarningModal = ({
  show,
  warningText,
  onButtonClick,
  onClose, // Pass the onClose function
  buttonText,
  secondaryText,
  extraText,
  secondExtraText,
  extraTextLoading = false,
  showCloseIcon = false,
  disableButton = false,
}: WarningModalProps) => {
  return (
    <>
      <CommonModal showModal={show} size={'sm'} className='shadow-2xl'>
        <div className='relative'>
          {/* Close Icon */}
          {showCloseIcon && (
            <IoCloseOutline
              className='absolute text-2xl top-2 right-0 text-gray-500 hover:text-gray-700 cursor-pointer'
              onClick={onClose}
            />
          )}

          <div className='text-center'>
            <IoWarningOutline className='text-yellow-500 text-5xl mx-auto' />
            <div className='text-gray-500 dark:text-gray-400 px-4 pt-2 pb-1 mt-2 text-[17px]'>
              {warningText}
            </div>
            <div className='h-[1px] w-full my-4 bg-[#323f55] dark:bg-#323f55'></div>
            {secondaryText && (
              <div className='text-gray-500 dark:text-gray-400 px-4 pb-2 mx-4 mt-4 text-sm'>
                {secondaryText}
              </div>
            )}
            {extraText && (
              <div className='flex gap-1 justify-center text-gray-500 dark:text-gray-400 px-2 pb-2 text-sm'>
                {extraText}
                <span className='mt-[11px]'>{extraTextLoading && <LoadingDots text={''} />}</span>
              </div>
            )}
            {secondExtraText && (
              <div className='text-gray-500 dark:text-gray-400 px-2 pb-2 mb-4 text-sm'>
                {secondExtraText}
              </div>
            )}
            <div className=''>
              <CommonButton
                onClick={onButtonClick}
                buttonClassName='my-auto mx-auto'
                buttonText={buttonText}
                disabled={disableButton}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default CommonWarningModal;
