import { gql } from '@apollo/client';

export const CREATE_TEAM = gql`
  mutation CreateTeam($team: TeamInput!) {
    createTeam(team: $team)
  }
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($team: TeamUpdateInput!) {
    updateTeam(team: $team)
  }
`;

export const ADD_MEMBER_TO_TEAM = gql`
  mutation MyMutation($member: String!, $teamId: String!) {
    addMemberToTeam(member: { member: $member, team_id: $teamId })
  }
`;

export const DELETE_MEMBER_FROM_TEAM = gql`
  mutation DeleteMemberFromTeam($member: String!, $teamId: String!, $id: Int!) {
    deleteMemberFromTeam(member: $member, team_id: $teamId, id: $id)
  }
`;

export const FETCH_TEAMS_OF_USER = gql`
  query FetchTeamsOfUser($userId: String!) {
    fetchTeamsOfUser(userId: $userId) {
      created_at
      id
      member
      ownership
      status
      team_id
      teams {
        created_at
        created_at
        description
        name
        profile_image
      }
    }
  }
`;

export const FETCH_TEAM_INFO = gql`
  query FetchTeamInfo($teamId: String!) {
    fetchTeamInfo(teamId: $teamId) {
      created_at
      creator_id
      description
      id
      members {
        created_at
        id
        member
        memberDetails {
          email
          id
          name
          profileImage
        }
        ownership
        status
        team_id
        joined_at
      }
      name
      profile_image
      team_id
    }
  }
`;

export const UPDATE_MEMBER_STATUS_MUTATION = gql`
  mutation UpdateMemberStatus($memberId: String!, $teamId: String!, $status: Int!) {
    updateMemberStatus(member_id: $memberId, team_id: $teamId, status: $status)
  }
`;

//SUBSCRIPTION

export const CREATE_TEAM_SUBSCRIPTION = gql`
  subscription createTeamSub($creatorId: String!) {
    createTeamSub(creatorId: $creatorId) {
      data
      step
      message
    }
  }
`;

export const UPDATE_TEAM_SUBSCRIPTION = gql`
  subscription updateTeamSub($updaterId: String!) {
    updateTeamSub(updaterId: $updaterId) {
      data
      step
      message
    }
  }
`;

export const ADD_MEMBER_SUBSCRIPTION = gql`
  subscription addTeamMemberSub($currentMemberId: String!) {
    addTeamMemberSub(currentMemberId: $currentMemberId) {
      data
      step
      message
    }
  }
`;

export const REMOVE_MEMBER_SUBSCRIPTION = gql`
  subscription removeTeamMemberSub($memberId: String!) {
    removeTeamMemberSub(memberId: $memberId) {
      data
      step
      message
    }
  }
`;

export const UPDATE_MEMBER_STATUS_SUB = gql`
  subscription updateMemberStatusSub($memberId: String!) {
    updateMemberStatusSub(memberId: $memberId) {
      data
      step
      message
    }
  }
`;
