import { gql } from '@apollo/client';

//FETCH QUERIES

export const ALL_SUBMISSIONS_FOR_CHALLENGE = gql`
  query AllSubmissionsForChallenge($challengeId: Int!) {
    fetchAllSubmissionsForGivenChallenge(challengeId: $challengeId) {
      challengeId
      creatorProfilePic
      team_id
      firstName
      lastName
      paymentWallet
      country
      creatorName
      created_on
      creator
      data {
        details
        title
        email
      }
      data_key
      id
      last_modified_on
      orgId
      status
      score
      reviews {
        challengeId
        created_on
        id
        reviewer
        scorecard {
          score
          title
        }
        submissionId
        comments
      }
      rank
      did
    }
  }
`;

export const FETCH_ALL_SUBMISSIONS_FOR_TEAM = gql`
  query AllSubmissionsForTeam($teamId: String!) {
    fetchAllSubmissionsForGivenTeam(teamId: $teamId) {
      challengeId
      creatorProfilePic
      team_id
      firstName
      lastName
      paymentWallet
      country
      creatorName
      created_on
      creator
      data {
        details
        title
        email
      }
      data_key
      id
      last_modified_on
      orgId
      status
      score
      reviews {
        challengeId
        created_on
        id
        reviewer
        scorecard {
          score
          title
        }
        submissionId
        comments
      }
      rank
      did
    }
  }
`;

export const FETCH_ALL_TEAM_PARTICIPATED_CHALLENGES = gql`
  query AllSubmissionsForTeam($teamId: String!) {
    fetchAllSubmissionsForGivenTeam(teamId: $teamId) {
      challengeId
    }
  }
`;

export const SUBMISSION_DETAILS = gql`
  query submissionDetails($submissionId: Int!) {
    fetchSubmissionDetails(submissionId: $submissionId) {
      id
      did
      challengeId
      created_on
      creator
      score
      data {
        details
        title
        email
      }
      data_key
      last_modified_on
      orgId
      status
      reviews {
        challengeId
        created_on
        id
        reviewer
        scorecard {
          score
          title
        }
        submissionId
        comments
      }
    }
  }
`;

export const FETCH_ALL_SUBMISSIONS_FOR_USER = gql`
  query fetchAllSubsForUser($profileAddress: String!) {
    fetchAllSubmissionsForGivenUser(profileAddress: $profileAddress) {
      challengeId
      created_on
      creatorProfilePic
      creatorName
      creator
      data_key
      score
      id
      last_modified_on
      orgId
      status
      data {
        details
        title
      }
      reviews {
        challengeId
        created_on
        id
        reviewer
        scorecard {
          score
          title
        }
        submissionId
        comments
      }
      rank
    }
  }
`;

export const FETCH_ALL_SUBMISSIONS_FOR_USER_USING_CHID = gql`
  query fetchAllSubsForUserUsingChid($profileAddress: String!, $challengeId: Int!) {
    fetchAllSubmissionsForGivenUserUsingChID(
      profileAddress: $profileAddress
      challengeId: $challengeId
    ) {
      challengeId
    }
  }
`;
export const FETCH_REVIEWS_OF_PROPOSAL = gql`
  query FetchReviewsOfProposal($proposalId: String!) {
    fetchReviewsOfProposal(proposalId: $proposalId) {
      reviewer
      challengeId
      comments
      created_on
      id
      last_modified_on
      scorecard {
        criteriaId
        reviewerWeight
        score
        title
      }
      submissionId
    }
  }
`;

export const ASSET_URL_VERIFICATION = gql`
  query checkURL($url: String!, $commonFileTypes: [String]!) {
    checkURL(url: $url, commonFileTypes: $commonFileTypes)
  }
`;

export const ESTIMATE_SUBMISSION = gql`
  query estimateSubmission(
    $challengeId: Int!
    $details: String!
    $reviewerSignature: String
    $submitterSignature: String
    $title: String!
    $submittedOn: Float!
    $submitter: String!
    $reviewedOn: Float
    $reviewer: String
    $rewardAmount: Int
    $rewardToken: String
    $status: Int
    $userProfileAddress: String!
    $orgId: String!
    $email: String!
    $asset: String!
  ) {
    estimateSubmission(
      challengeId: $challengeId
      data: {
        asset: $asset
        details: $details
        reviewerSignature: $reviewerSignature
        submitterSignature: $submitterSignature
        title: $title
        email: $email
      }
      details: {
        challengeId: $challengeId
        submittedOn: $submittedOn
        submitter: $submitter
        reviewedOn: $reviewedOn
        reviewer: $reviewer
        rewardAmount: $rewardAmount
        rewardToken: $rewardToken
        status: $status
      }
      userProfileAddress: $userProfileAddress
      orgId: $orgId
    )
  }
`;

//MUTATIONS

export const CREATE_SUBMISSION_MUTATION = gql`
  mutation MyMutation(
    $challengeId: Int!
    $details: String!
    $reviewerSignature: String
    $submitterSignature: String
    $title: String!
    $submittedOn: Float!
    $submitter: String!
    $reviewedOn: Float
    $reviewer: String
    $rewardAmount: Int
    $rewardToken: String
    $status: Int
    $userProfileAddress: String!
    $orgId: String!
    $email: String!
    $asset: String!
    $teamAddress: String
  ) {
    createSubmission(
      challengeId: $challengeId
      data: {
        asset: $asset
        details: $details
        reviewerSignature: $reviewerSignature
        submitterSignature: $submitterSignature
        title: $title
        email: $email
      }
      details: {
        challengeId: $challengeId
        submittedOn: $submittedOn
        submitter: $submitter
        reviewedOn: $reviewedOn
        reviewer: $reviewer
        rewardAmount: $rewardAmount
        rewardToken: $rewardToken
        status: $status
      }
      userProfileAddress: $userProfileAddress
      orgId: $orgId
      teamAddress: $teamAddress
    )
  }
`;

export const CREATE_SUBMISSION_ON_DB_MUTATION = gql`
  mutation MyMutation(
    $challengeId: Int!
    $details: String!
    $reviewerSignature: String
    $submitterSignature: String
    $title: String!
    $submittedOn: Float!
    $submitter: String!
    $reviewedOn: Float
    $reviewer: String
    $rewardAmount: Int
    $rewardToken: String
    $status: Int
    $userProfileAddress: String!
    $orgId: String!
    $email: String!
    $asset: String!
    $teamAddress: String
  ) {
    createSubmissionForDB(
      challengeId: $challengeId
      data: {
        asset: $asset
        details: $details
        reviewerSignature: $reviewerSignature
        submitterSignature: $submitterSignature
        title: $title
        email: $email
      }
      details: {
        challengeId: $challengeId
        submittedOn: $submittedOn
        submitter: $submitter
        reviewedOn: $reviewedOn
        reviewer: $reviewer
        rewardAmount: $rewardAmount
        rewardToken: $rewardToken
        status: $status
      }
      userProfileAddress: $userProfileAddress
      orgId: $orgId
      teamAddress: $teamAddress
    )
  }
`;

export const CREATE_TEMP_SUBMISSION_MUTATION = gql`
  mutation CreateTempSUbmission(
    $challengeId: Int!
    $details: String!
    $reviewerSignature: String
    $submitterSignature: String
    $title: String!
    $submittedOn: Float!
    $submitter: String!
    $reviewedOn: Float
    $reviewer: String
    $rewardAmount: Int
    $rewardToken: String
    $status: Int
    $userProfileAddress: String!
    $orgId: String!
    $email: String!
    $asset: String!
  ) {
    createTempSubmission(
      challengeId: $challengeId
      data: {
        asset: $asset
        details: $details
        reviewerSignature: $reviewerSignature
        submitterSignature: $submitterSignature
        title: $title
        email: $email
      }
      details: {
        challengeId: $challengeId
        submittedOn: $submittedOn
        submitter: $submitter
        reviewedOn: $reviewedOn
        reviewer: $reviewer
        rewardAmount: $rewardAmount
        rewardToken: $rewardToken
        status: $status
      }
      userProfileAddress: $userProfileAddress
      orgId: $orgId
    )
  }
`;

export const DELETE_SUBMISSION = gql`
  mutation DeleteSubmission($submissionId: Int!) {
    deleteSubmissionUsingId(submissionId: $submissionId)
  }
`;

export const CREATE_REVIEW_MUTATION = gql`
  mutation CreateReview($review: ReviewInput!) {
    createReview(review: $review) {
      id
      submissionId
      challengeId
      reviewer
      scorecard {
        title
        score
        criteriaId
      }
      created_on
      last_modified_on
      comments
    }
  }
`;

export const UPDATE_REVIEW_MUTATION = gql`
  mutation UpdateReview($id: Int!, $review: ReviewInput!) {
    updateReview(id: $id, review: $review) {
      challengeId
      created_on
      id
      reviewer
      last_modified_on
      scorecard {
        score
        title
        criteriaId
      }
      submissionId
      comments
    }
  }
`;

export const GENERATE_SUBMISSION_CSV = gql`
  mutation GenerateSubmissionCSV($challengeId: Int!) {
    generateSubmissionCSV(challengeId: $challengeId)
  }
`;

export const GET_PUBLIC_KEY = gql`
  mutation ServerPublicKey {
    serverPublicKey
  }
`;

export const SIGN_DATA_MUTATION = gql`
  mutation SignData($signatureData: String!) {
    signData(signatureData: $signatureData)
  }
`;

export const LAZY_FUND_MUTATION = gql`
  mutation LazyFundMutation($filesize: Int!) {
    lazyFundMutation(filesize: $filesize)
  }
`;

//SUBSCRIPTIONS

export const CREATE_SUBMISSION_SUBSCRIPTION = gql`
  subscription createSubmissionSub($submitter: String!) {
    createSubmissionSub(submitter: $submitter) {
      data
      message
      step
    }
  }
`;

export const CREATE_REVIEW_SUB = gql`
  subscription createReviewSub($reviewer: String!) {
    createReviewSub(reviewer: $reviewer) {
      data
      message
      step
    }
  }
`;

export const UPDATE_REVIEW_SUB = gql`
  subscription updateReviewSub($reviewer: String!) {
    updateReviewSub(reviewer: $reviewer) {
      data
      message
      step
    }
  }
`;
