import { Button, Modal, Spinner } from 'flowbite-react';
import { TiWarning } from 'react-icons/ti';
import { TbArrowBackUp } from 'react-icons/tb';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
// import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { useEffect, useState } from 'react';
import { HERO_PATH } from '../Utils/Constants';
import { supportedChain } from '../Utils/Helpers';

// This page will restrict the user from switching network after connecting the wallet.
const NetworkSwitchListener = () => {
  const [showModal, setShowModal] = useState(false);
  const { isConnected, status } = useAccount();
  const { chain: currentChain } = useNetwork();
  const { isLoading, switchNetwork } = useSwitchNetwork();
  const chainValues = process.env.REACT_APP_CHAIN_ID || 137;

  useEffect(() => {
    // As for now we only support polygon chain.
    if (isConnected) {
      console.log('status', status);
      if (currentChain?.id != chainValues) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  }, [currentChain?.id]);

  const handleBackClick = () => {
    switchNetwork?.(supportedChain[0].id);
  };

  useEffect(() => {
    console.log(supportedChain);
  }, [supportedChain]);

  return (
    <Modal show={showModal && location.pathname !== HERO_PATH} size='md'>
      <Modal.Body className='text-center'>
        <div className='flex justify-center'>
          <TiWarning className='text-amber-400 text-6xl self-center' />
        </div>
        <h3 className='mb-1 text-center text-lg font-semibold text-gray-900 dark:text-white'>
          Network not supported
        </h3>
        <div className='text-gray-900 dark:text-gray-300'>
          OOPS! This site only supports {supportedChain[0]?.name}. Please switch back to{' '}
          {supportedChain[0]?.name} from your wallet.
        </div>
        <Button className='mx-auto mt-4 w-fit' onClick={handleBackClick} disabled={isLoading}>
          {isLoading && (
            <div className='mr-3'>
              <Spinner size='sm' light={true} />
            </div>
          )}
          {!isLoading && <TbArrowBackUp className='text-white text-lg md:text-2xl' />}
          <span className='md:text-lg ml-2'>Switch back to {supportedChain[0]?.name}</span>
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default NetworkSwitchListener;
