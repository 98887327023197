import { gql } from '@apollo/client';

export const GET_CREDITS_MULTIPLE = gql`
  query GetCreditsMultiple {
    getCreditsMultiple {
      forPointMultiple
      forTokenMultiple
    }
  }
`;

export const ASSIGN_CREDITS_TO_USER = gql`
  mutation AssignCreditsToUser(
    $amt: Float!
    $user_id: ID!
    $tx_hash: String!
    $signature: String!
  ) {
    assignCreditsToUser(amt: $amt, user_id: $user_id, tx_hash: $tx_hash, signature: $signature)
  }
`;

export const ASSIGN_CREDITS_TO_USER_USING_POINTS = gql`
  mutation assignCreditsToUserUsingPoints(
    $amt: Float!
    $nonce: String!
    $signature: String!
    $user_id: ID!
  ) {
    assignCreditsToUserUsingPoints(
      amt: $amt
      nonce: $nonce
      signature: $signature
      user_id: $user_id
    )
  }
`;

export const GET_CREDITS_COST = gql`
  query MyQuery {
    getCreditsCost {
      forCreatingOrg
      forSubmission
      forCreatingTeam
      forTeamInviteAccept
      forCreateChallenge
    }
  }
`;

export const VERIFY_AND_REDEEM_CREDITS = gql`
  mutation VerifyAndRedeemCredits(
    $nonce: String!
    $redeemEnvName: String!
    $signature: String!
    $userId: ID!
  ) {
    verifyAndRedeemCredits(
      nonce: $nonce
      redeem_env_name: $redeemEnvName
      signature: $signature
      user_id: $userId
    )
  }
`;
