import { gql } from '@apollo/client';
export const FETCH_USER_PROFILE_DETAILS = gql`
  query ($id: String!) {
    fetchUserPage(id: $id) {
      created_on
      data {
        bio
        discord
        email
        github
        kaggle
        linkedin
        name
        profileImage
        telegram
        twitter
        website
      }
      data_key
      id
      last_modified_on
      manager
      owner
      totalChallenges
      totalRewardsInUsd
      totalSubmissions
      subscribed
      news_last_seen
      firstname
      lastname
      country
      payment_wallet
      unread_notification_count
      points
      credits
    }
  }
`;

export const FETCH_USER_PROFILE_DATA = gql`
  query fetchUserProfileDetails($profileAddress: String!) {
    fetchUserProfileData(profileAddress: $profileAddress) {
      bio
      discord
      email
      github
      kaggle
      linkedin
      name
      profileImage
      telegram
      twitter
      website
    }
  }
`;

export const FETCH_USER_METRICS = gql`
  query fetchUserWithMetrics($profileId: String!) {
    fetchUserWithMetrics(profileId: $profileId) {
      rank
      totalEarnings
      totalScore
      totalSubmissions
    }
  }
`;

export const GET_ORGANIZATIONS_FOR_USER = gql`
  query MyQuery($profileAddress: String!) {
    fetchAllOrganisationsForGivenUser(profileAddress: $profileAddress)
  }
`;

export const FETCH_USER_ADDRESS = gql`
  query fetchUserAddress($address: String!) {
    fetchUserProfileAddress(walletAddress: $address)
  }
`;

export const IS_USER_PROFILE_REGISTERED = gql`
  query isProfileRegistered($address: String!) {
    isUserProfileRegistered(profileAddress: $address)
  }
`;

export const FETCH_ALL_ADMINS = gql`
  query FetchAllAdmins($profileAddress: String!) {
    fetchAllAdmins(profileAddress: $profileAddress)
  }
`;

export const FETCH_ALL_ORGS_FOR_USER = gql`
  query FetchAllOrgsForGivenUser($profileAddress: String!) {
    fetchAllOrganisationsForGivenUser(profileAddress: $profileAddress)
  }
`;

export const CheckIsWalletInvited = gql`
  query isWalletInvited($registryAddress: String!, $walletAddress: String!) {
    checkIfWalletIsInvited(registryAddress: $registryAddress, walletAddress: $walletAddress)
  }
`;

/**This is for org admins  */
export const GET_ADMINS_WITH_INFO = gql`
  query allAdminsWithInfo($profileAddress: String!) {
    allAdminsWithInfo(profileAddress: $profileAddress) {
      created_on
      data {
        name
        profileImage
        email
      }
      id
    }
  }
`;

export const FETCH_ALL_ADMINS_FOR_USER = gql`
  query MyQuery($profileAddress: String!) {
    allUserAdmins(profileAddress: $profileAddress) {
      label
      walletAddress
    }
  }
`;

export const FETCH_LATEST_NEWS = gql`
  query GetLatestVersion {
    getLastVersion {
      button_text
      created_on
      description
      id
      title
    }
  }
`;

export const FETCH_USERS_LEADERBOARD = gql`
  query FetchUsersLeaderboard {
    fetchUsersLeaderboard {
      id
      name
      profileImage
      rank
      totalScore
    }
  }
`;

//To fetch all the challenges in which a user has participated

export const FETCH_USER_PARTICIPATED_CHALLENGES = gql`
  query FetchUserParticipatedChallenges($userId: String!) {
    fetchUsersParticipatedChallenges(userId: $userId) {
      challengeId
    }
  }
`;

// MUTATIONS

export const UPDATE_USER_PROFILE_DETAILS = gql`
  mutation updateUserProfileDetails($newProfileData: ProfileData!, $profileAddress: String!) {
    updateUserProfileData(newProfileData: $newProfileData, profileAddress: $profileAddress)
  }
`;

export const CREATE_USER_PROFILE = gql`
  mutation createUserProfile($controller: String!, $profileData: ProfileData!) {
    createUserProfile(controller: $controller, profileData: $profileData)
  }
`;

export const FETCH_SUBMISSION_DETAILS = gql`
  query fetchSubmissionDetails($submissionId: Int!) {
    fetchSubmissionDetails(submissionId: $submissionId) {
      assets
      challengeId
      details
      reviewedOn
      reviewer
      reviewerSignature
      rewardAmount
      rewardToken
      status
      submittedOn
      submitter
      submitterSignature
      title
    }
  }
`;

export const ADD_ADMIN_TO_USER = gql`
  mutation addAdminToUser(
    $address: String!
    $adminToAdd: String!
    $userProfileAddress: String!
    $label: String
  ) {
    addAdminToUser(
      address: $address
      adminToAdd: $adminToAdd
      userProfileAddress: $userProfileAddress
      label: $label
    )
  }
`;

export const REMOVE_ADMIN_OF_USER = gql`
  mutation removeAdminForUser(
    $address: String!
    $adminToRemove: String!
    $userProfileAddress: String!
  ) {
    removeAdminForUser(
      address: $address
      adminToRemove: $adminToRemove
      userProfileAddress: $userProfileAddress
    )
  }
`;

export const UPDATE_SUBSCRIPTION_STATUS = gql`
  mutation UpdateSubscription($userId: String!, $subStatus: Boolean!) {
    updateIsUserSubscribed(userId: $userId, subStatus: $subStatus)
  }
`;

export const UPDATE_LAST_SEEN = gql`
  mutation UpdateLastSeen($userId: String!, $version: Int!) {
    updateLastSeen(userId: $userId, version: $version)
  }
`;
// SUBSCRIPTIONS

export const UPDATE_USER_SUBSCRIPTION = gql`
  subscription updateUserSubscription($profileAddress: String!) {
    updateUserProfile(profileAddress: $profileAddress) {
      data
      message
      step
    }
  }
`;

export const CREATE_USER_SUBSCRIPTION = gql`
  subscription createUserSub($controller: String!) {
    newUserProfile(controller: $controller) {
      data
      message
      step
    }
  }
`;

export const ADD_ADMIN_TO_USER_PROFILE_SUB = gql`
  subscription addAdminToUserProfile($userProfileAddress: String!) {
    addAdminToUserProfileSub(userProfileAddress: $userProfileAddress) {
      data
      message
      step
    }
  }
`;

export const REMOVE_ADMIN_OF_USER_PROFILE_SUB = gql`
  subscription removeAdminOfUserProfileSub($userProfileAddress: String!) {
    removeAdminOfUserProfileSub(userProfileAddress: $userProfileAddress) {
      data
      message
      step
    }
  }
`;
