import { Avatar } from 'flowbite-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonAvatarProps, RootState } from '../@types';
import { useProfile } from '../Utils/Hooks';
import {
  USER_PROFILE_DETAILS_PATH,
  GUEST_ORG_DETAILS_PATH,
  ORG_PROFILE_DETAILS_PATH,
  USER_ORG_DETAILS_PATH,
  SHARED_PATH,
} from '../Utils/Constants';

const CommonAvatar = ({
  img,
  size,
  wrapperClasses = '',
  profileId,
  navigation = true,
  imageClasses,
}: CommonAvatarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedProfile, isProfileAvailable } = useProfile(profileId);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);

  const profileIcon = useMemo(() => {
    let imageString: string = img ?? '';
    if (isProfileAvailable) imageString = selectedProfile.profileImage ?? '';
    if (imageString?.startsWith('http') || imageString.startsWith('data:image')) return imageString;
    const imgData = localStorage.getItem(imageString) ?? '';
    return imgData;
  }, [selectedProfile, img]);

  const handleNavigation = () => {
    if (navigation && profileId && !location.pathname.includes(SHARED_PATH)) {
      let path = USER_PROFILE_DETAILS_PATH;
      if (isGuest) path = GUEST_ORG_DETAILS_PATH;
      if (selectedProfile.isOrganization) {
        if (isOrgSelected) {
          if (selectedProfile.profileId === currentProfile.profileId) {
            path = ORG_PROFILE_DETAILS_PATH;
          } else {
            path = ORG_PROFILE_DETAILS_PATH;
          }
        } else {
          path = USER_ORG_DETAILS_PATH;
        }
      }
      navigate([path, profileId].join('/'));
    }
  };

  return (
    <div
      className={`w-fit h-fit mx-auto min-w-max bg-white rounded-full shadow-lg dark:shadow-gray-600 self-center ${imageClasses} ${
        navigation && !location.pathname.includes(SHARED_PATH) ? 'cursor-pointer' : ''
      } ${wrapperClasses}`}
      onClick={handleNavigation}
    >
      <Avatar img={profileIcon} rounded={true} size={size} />
    </div>
  );
};

export default CommonAvatar;
