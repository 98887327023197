import { gql } from '@apollo/client';

export const GET_ALL_ORG_PROFILES = gql`
  query AllOrgProfiles {
    allProfiles: fetchAllOrgProfiles
  }
`;

export const ORG_PROFILE_DATA_QUERY = gql`
  query OrgProfileData($profileAddress: String!) {
    fetchOrgProfileData(profileAddress: $profileAddress) {
      bio
      discord
      email
      github
      industry
      linkedin
      name
    }
  }
`;

export const ALL_CHALLENGES_FOR_ORG_QUERY = gql`
  query AllChallengesForOrg($profileAddress: String!) {
    fetchAllChallengesForOrg(profileAddress: $profileAddress) {
      challengeId
      createdOn
      deadline
      orgProfileAddress
      rewardAmount
      rewardToken
    }
  }
`;

export const ALL_ORGANISATIONS = gql`
  query AllOrganisations {
    fetchAllOrganisationsPage {
      activeChallengesCount
      admins(id: "")
      id(id: "")
      manager
      challenges {
        created_on
        deadline
        id
        last_modified_on
        org
        orglogo
        criterias {
          description
          score
          title
        }
        reviewers {
          reviewer
          weight
        }
        reward_amount
        reward_amount_usd
        reward_token
        sections {
          content
          name
          order
        }
        status
        summary
        tags
        title
        type
      }
      profile {
        bio
        discord
        email
        github
        industry
        linkedin
        name
        profileImage
        telegram
        twitter
        website
      }
      totalRewardAmountInUsd
      publishedChallengesCount
      totalSubmissions
    }
  }
`;

export const FETCH_ORG_DETAILS = gql`
  query fetchOrgDetails($profileAddress: String!) {
    fetchOrgPage(profileAddress: $profileAddress) {
      admins(id: "")
      challenges {
        created_on
        deadline
        id
        last_modified_on
        org
        orglogo
        criterias {
          description
          score
          title
        }
        reviewers {
          reviewer
          weight
        }
        reward_amount
        reward_amount_usd
        reward_token
        sections {
          content
          name
          order
        }
        status
        summary
        tags
        title
        type
      }
      id(id: "")
      manager
      profile {
        bio
        discord
        email
        github
        industry
        linkedin
        name
        profileImage
        telegram
        twitter
        website
      }
    }
  }
`;

export const FETCH_ORG_METRICS = gql`
  query fetchOrgWithMetrics($profileAddress: String!) {
    fetchOrgWithMetrics(profileAddress: $profileAddress) {
      rank
      totalChallengesFunded
      totalPrizes
      totalSubmissions
    }
  }
`;

export const FETCH_ALL_ORG_IDS = gql`
  query fetchAllOrfProfiles {
    fetchAllOrgProfiles
  }
`;

export const ESTIMATE_CREATE_ORG = gql`
  query estimateCreateOrg($userProfileAddress: String!) {
    estimateCreateOrgProfile(userProfileAddress: $userProfileAddress)
  }
`;

// MUTATIONS

export const CREATE_ORG_PROFILE = gql`
  mutation CreateOrgProfile($userProfileAddress: String!, $profileData: OrgProfileData!) {
    createOrgProfile(userProfileAddress: $userProfileAddress, profileData: $profileData)
  }
`;

export const UPDATE_ORG_PROFILE = gql`
  mutation UpdateOrgProfileData($orgProfileAddress: String!, $newProfileData: OrgProfileData!) {
    updateOrgProfileData(orgProfileAddress: $orgProfileAddress, newProfileData: $newProfileData)
  }
`;

export const ADD_ADMIN_TO_ORG = gql`
  mutation addAdmintoOrg($adminToAdd: String!, $orgProfileAddress: String!) {
    addAdminToOrg(adminToAdd: $adminToAdd, orgProfileAddress: $orgProfileAddress)
  }
`;

export const REMOVE_ADMIN_FROM_ORG = gql`
  mutation removeAdminFromOrg($adminToRemove: String!, $orgProfileAddress: String!) {
    removeAdminFromOrg(adminToRemove: $adminToRemove, orgProfileAddress: $orgProfileAddress)
  }
`;

// SUBSCRIPTIONS

export const CREATE_ORG_PROFILE_SUBSCRIPTION = gql`
  subscription newOrgProfileSub($userProfileAddress: String!) {
    newOrgProfile(userProfileAddress: $userProfileAddress) {
      data
      message
      step
    }
  }
`;

export const UPDATE_ORG_PROFILE_SUBSCRIPTION = gql`
  subscription updateOrgProfileSub($orgProfileAddress: String!) {
    updateOrgProfile(orgProfileAddress: $orgProfileAddress) {
      data
      step
      message
    }
  }
`;

export const ADD_ADMIN_TO_ORG_SUBSCRIPTION = gql`
  subscription addAdminToOrg($orgProfileAddress: String!) {
    addAdminToOrg(orgProfileAddress: $orgProfileAddress) {
      data
      message
      step
    }
  }
`;

export const REMOVE_ADMIN_FROM_ORG_SUBSCRIPTION = gql`
  subscription removeAdminFromOrg($orgProfileAddress: String!) {
    removeAdminFromOrg(orgProfileAddress: $orgProfileAddress) {
      data
      message
      step
    }
  }
`;
