import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Spinner } from 'flowbite-react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { AppDispatch, Profile, RootState, sidebarProfileDropDownInterface } from '../../@types';
import {
  CREATE_TEAM_PATH,
  NOT_ENOUGH_CREDITS_FOR_ORG_WARNING,
  NOT_ENOUGH_CREDITS_FOR_TEAM_WARNING,
  ORG_CHALLENGE_LIST_PATH,
  ORG_PROFILE_CREATE_PATH,
  TEAM_SUBMISSIONS_LIST_PATH,
  USER_CREDITS_PATH,
  USER_SUBMISSIONS_LIST_PATH,
} from '../../Utils/Constants';
import CommonAvatar from '../../Components/CommonAvatar';
import { updateCurrentProfileAction, updateSelectedProfileAction } from '../../Store/Actions';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import CommonWarningModal from '../../Components/Modals/CommonWarningModal';
import { GET_CREDITS_COST } from '../../graphql/credits';

export const SidebarProfileDropdown = ({
  ProfileUserData,
  showListselected,
  setEnableProfileDropdown,
  endListLoading,
}: sidebarProfileDropDownInterface) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showWarningModalForTeam, setShowWarningModalForTeam] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const curCredits = useSelector((state: RootState) => state.profile.currentProfile.credits);

  const { loading, data } = useQuery<CreditsCostData>(GET_CREDITS_COST);

  const pointModalCloseHandler = () => {
    setShowWarningModal(false);
    setShowWarningModalForTeam(false);
    navigate(USER_CREDITS_PATH);
    setEnableProfileDropdown(false);
  };

  useEffect(() => {
    if (data?.getCreditsCost) {
      console.log('forCreatingOrg', data?.getCreditsCost);
    }
    if (loading) {
      console.log('loading');
    }
  }, [loading, data]);

  return (
    <>
      <ListGroup className='!border-0 dark:bg-gray-800'>
        {ProfileUserData.map((profile: Profile, index: number) => {
          const { profileImage, profileId, name, isOrganization, isTeam } = profile;
          return (
            <div className='mt-1 profile_section' key={index}>
              <ListGroup.Item
                onClick={() => {
                  dispatch(
                    updateSelectedProfileAction({ profileId, isOrg: isOrganization, isTeam }),
                  );
                  dispatch(updateCurrentProfileAction(profile));
                  navigate(
                    isOrganization
                      ? ORG_CHALLENGE_LIST_PATH
                      : isTeam
                        ? TEAM_SUBMISSIONS_LIST_PATH
                        : USER_SUBMISSIONS_LIST_PATH,
                  );
                  setEnableProfileDropdown(false);
                }}
              >
                <div
                  className={`flex w-full rounded-lg p-2 hover:dark:bg-gray-700 ${
                    profile.profileId == currentProfile.profileId
                      ? 'bg-primary-50 dark:bg-gray-700'
                      : ''
                  }`}
                >
                  <CommonAvatar img={profileImage} size='sm' />
                  <div className='pl-3 w-full text-start'>
                    <div className='truncate pr-3'>{name}</div>
                    <div className='text-start text-sm text-gray-500 dark:text-gray-400'>
                      {isOrganization
                        ? 'Organization Profile'
                        : isTeam
                          ? 'Team Profile'
                          : 'User Profile'}
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            </div>
          );
        })}

        {/* Handling loading state */}
        {endListLoading && (
          <div className='flex justify-center'>
            <Spinner size='lg' />
          </div>
        )}

        {showListselected.login && (
          <>
            <hr className='border-gray-200 dark:border-gray-700 mt-2 mb-2' />
            <div className='profile_section'>
              <ListGroup.Item
                active={false}
                onClick={() => {
                  if (curCredits < data?.getCreditsCost?.forCreatingOrg) {
                    setShowWarningModal(true);
                    return;
                  }
                  navigate(ORG_PROFILE_CREATE_PATH);
                  setEnableProfileDropdown(false);
                }}
              >
                <div className='flex w-full items-center py-2 px-2 hover:dark:bg-gray-700 rounded-lg'>
                  <div>
                    <AiOutlinePlusCircle size={25} color='primary' />
                  </div>
                  <div className='pl-3'>Create Organization</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                active={false}
                onClick={() => {
                  if (curCredits < data?.getCreditsCost?.forCreatingTeam) {
                    setShowWarningModalForTeam(true);
                    return;
                  }
                  navigate(CREATE_TEAM_PATH);
                  setEnableProfileDropdown(false);
                }}
              >
                <div className='flex w-full items-center py-2 px-2 hover:dark:bg-gray-700 rounded-lg'>
                  <div>
                    <AiOutlinePlusCircle size={25} color='primary' />
                  </div>
                  <div className='pl-3'>Create Team</div>
                </div>
              </ListGroup.Item>
            </div>
          </>
        )}
      </ListGroup>

      {/* Common warning modal(for org and team) for insufficient credits */}
      <CommonWarningModal
        show={showWarningModal || showWarningModalForTeam}
        warningText={
          showWarningModalForTeam
            ? NOT_ENOUGH_CREDITS_FOR_TEAM_WARNING
            : NOT_ENOUGH_CREDITS_FOR_ORG_WARNING
        }
        onButtonClick={pointModalCloseHandler}
        buttonText='Buy Credits'
        secondaryText={`Required Credits: ${showWarningModalForTeam ? data?.getCreditsCost?.forCreatingTeam : data?.getCreditsCost?.forCreatingOrg}`}
        extraTextLoading={loading}
        secondExtraText={`Your Credits: ${currentProfile?.credits ?? 'N/A'} `}
        onClose={() => {
          setShowWarningModal(false);
          setShowWarningModalForTeam(false);
        }}
        showCloseIcon={true}
      />
    </>
  );
};
